:root {
    --primaryColor: #ffc507;  
    --secondaryColor: #E45A00; 
    --hColor: #000;
    --pColor: #6f6f6f;  
}
html {
    scroll-behavior: smooth;
    background: #ebebeb;
}
body {    
    color: var(--pColor);
    font-size: 15px !important;
    font-weight: 300 !important; 
    line-height: 1.8 !important;
    letter-spacing: 0.15px;
    overflow-x: hidden !important;
    font-family: "Manrope", sans-serif !important;
    font-optical-sizing: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    background: #ebebeb !important;
}
* {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none !important;
}
a:focus-visible {
    box-shadow: none !important;
}
.form-control:focus {
    box-shadow: none !important;
}
iframe {
    z-index: -1 !important;
}
h2 {
    font-size: 40px !important;
    color: var(--hColor) !important;
    margin-bottom: 20px !important;
    text-transform: uppercase;
}
h2 span {
    font-weight: 700;
    color: var(--primaryColor);
}
.primaryBtn, .primaryBtn:focus {
    background: var(--primaryColor);
    color: var(--hColor);
    text-align: center;
    height: 42px;
    padding: 6px 30px 8px 30px;
    display: inline-block;
    font-weight: 500;
    opacity: 1;
    transition: all ease-out .5s;
    position: relative;
}
.primaryBtn:hover {
	opacity: 0.8;	
}
/* width */
/* ::-webkit-scrollbar {
    width: 4px;
}
  
::-webkit-scrollbar-track {
    background: #fff; 
}
   
::-webkit-scrollbar-thumb {
    background: #6d6d6d; 
}
  
::-webkit-scrollbar-thumb:hover {
    background: #6d6d6d; 
} */
/* Navbar Start */
.navigation {
    padding: 0;
    margin: -10px 0;
}
.navbar {
    background: #fff;
    transition: all .4s ease-in-out;
    box-shadow: 0px 5px 50px rgb(3 24 55 / 10%);
}
.navbar .dropdown.active a {
    color: var(--primaryColor);
}
.navbar .dropdown-menu a {
    color: var(--hColor) !important;
}
.navbar-brand img {
    max-width: 160px;
    transition: all .4s ease-in-out;
    margin-bottom: -8px;
}
.navbar.active .navbar-brand img {
    max-width: 150px;
}
.navbar .navbar-brand #two {
    display:none;
    transition: all .4s ease-in-out;
}
.navbar.active .navbar-brand #one {
    display:none;
    transition: all .4s ease-in-out;
}
.navbar.active .navbar-brand #two {
    display: inherit;
    transition: all .4s ease-in-out;
}
.navbar-nav .nav-link, .navbar-nav .nav-link:focus {
    padding: 12px 8px;
    margin: 0 4px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .5px;
}
.navbar.active .navbar-nav .nav-link.active, .navbar.active .navbar-nav .nav-link:hover, .navbar.active .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link:hover, .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar.active .navbar-nav .nav-link.active, .navbar.active .navbar-nav .nav-link:hover, .navbar.active .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar .navbar-nav .nav-link.active, .navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.show {
    color: var(--primaryColor) !important;
}
.navbar .navbar-nav .nav-link, .navbar-nav .nav-link:focus {
    color: var(--hColor);
}
.dropdown-item {
    font-size: 15px;
}
.dropdown-menu {
    top: 50px !important;
}
.dropdown-menu a {
    color: var(--hpColor);
    padding: 8px 26px;
    display: block;
    border-bottom: 1px solid #e2e0e0f7;
    font-size: 13px;
    text-transform: uppercase;
}
.dropend .dropdown-menu[data-bs-popper] {
    top: 0 !important;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer);
}
.dropdown-item img {
    width: 25px;
    margin-right: 5px;
}
.serviceMenu {
    border-bottom: 1px solid #e2e0e0f7;
}
.serviceMenu a {
    color: var(--hColor) !important; 
    padding: 8px 52px 8px 26px !important; 
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    border-bottom: none;
}
.serviceMenu .dropdown-menu.show a {
    border-bottom: 1px solid #e2e0e0f7;
}
.serviceMenu .dropdown-menu.show a:last-child {
    border-bottom: none;
}
.dropend .dropdown-toggle::after {
    border-top: .35em solid transparent;
    border-bottom: .35em solid transparent;
    border-left: .35em solid;
}
.dropdown-menu a:last-child {
    border-bottom: none;
}
.dropdown-menu a:hover, .dropdown-menu a:focus {
    color: var(--hColor) !important;
    background-color: var(--bs-dropdown-link-hover-bg) !important;
}
.navbar .dropdown-menu {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    display: block; 
    visibility: hidden;
    border: none;
    box-shadow: 0px 5px 50px rgb(3 24 55 / 12%);
}

.navbar .dropdown-menu.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}
.navbar-toggler, .navbar-toggler:focus {
    border: none !important;
    box-shadow: none !important;
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--hColor) !important;
    text-decoration: none;
    background-color: #f8f9fa;
    background: inherit !important;
}
.navbar-toggler-icon {
    background-image: url('../img/navicon.svg') !important;
}
.navbar-toggler-icon {
    width: 1.35em !important;
    height: 1.35em !important;
}
.navbar-toggler {
    padding: 0 !important;
}
.navbar .primaryBtn {
    height: 38px;
    margin-top: 3px;
    margin-left: 40px;
    font-weight: 500;
    padding: 4px 22px 8px 22px;
}
.leftSocial {
    position: fixed;
    right: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-end;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 50px;
    z-index: 10;
}
.leftSocial img {
    width: 36px;
    height: 36px;
    margin: 5px 0;
    background: #fff;
    padding: 5px;
    border: 1px solid #ebebeb;
    transition: all 0.3s ease-in-out;
}
.leftSocial a {
    position: relative;
    z-index: 1;
}
.leftSocial a:hover img {
    width: 36px;
    height: 36px;
    margin: 5px 0;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}
.leftSocial h4 {
    opacity: .6;
    font-size: 18px;
    padding-right: 85px;
    transform: rotate(-90deg);
}
.whatsappIcon img {
    width: 55px !important;
    margin: 2px 0 !important;
}
.brochureBtn img {
    max-width: 105px;
}
.brochureBtn img:hover {
    opacity: .8;
}
.brochureBtn {
	transform-origin: top center;
	animation: swing 2s ease infinite;
}
@keyframes swing {
	20% { transform: rotate(15deg); }	
	40% { transform: rotate(-10deg); }
	60% { transform: rotate(5deg); }	
	80% { transform: rotate(-5deg); }	
	100% { transform: rotate(0deg); }
}
/* Navbar End */
/* Home Banner Start */
.homeBanner {
    padding: 165px 0 75px 0;
    /* text-align: center; */
}
.homeBanner h1 .span1 {
    color: #CBCBCB !important;
}
.home-banner-text-animate {
    background-image: linear-gradient(-225deg, #0079C1 0%, #E54796 29%, #E45A00 67%, #aaa51c 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
  }
  
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
.homeBanner p {
    font-size: 17px;
    color: var(--hColor);
    font-weight: 500;
    margin-top: 30px;
    border-left: 3px solid #CBCBCB;
    padding-left: 20px;
}
.homeBanner h1 .span2 img {
    animation: wiggle 2s linear infinite;
}
@keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }
  .homeCounter {
    padding-bottom: 95px;
  }
  .homeCounter .homeCounter-box {
    background-attachment: fixed;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .homeCounter hr {
    border-bottom: .5px solid #e5e9f1;
  }
  .homeCounter-box h3 {
    font-size: 48px;
    max-width: 90%;
    line-height: 1.35;
    font-weight: 600;
  }
  .homeCounter-counter {
    display: block;
  }
  .homeCounter-counter h4 {
    color: #fff;
    margin-bottom: 35px;
  }
    .media-count-value {
        font-size: 58px;
        color: #fff;
        line-height: 1;
        padding: 0;
        display: block;
        font-weight: 700;
    }
  .homeCounter-box1 {
    padding-bottom: 10px;
  }
  .homeCounter-box1 p {
    padding: 20px 0;
  }
  .homeCounter-box2 {
    padding-top: 15px;
  }
  .homeCounter-box2 h4 {
    font-size: 46px;
    font-weight: 700;
    color: var(--secondaryColor);
  }
  .homeCounter-plus {
    color: #b6b6b6;
    font-size: 70px;
    margin-left: -10px;
  }
  .homeCounter-title {
    display: block;
    font-size: 18px;
    font-weight: 400;
    color: #b6b6b6;
  }
  .homeService {
    background-image: url(../img/why-choose-bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 85px 0 25px 0;
    position: relative;
    z-index: 1;
  }
  .homeService::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 75%);
    z-index: -1;
  }
  .homeService-img h2 {
    color: #fff !important;
    font-size: 40px !important;
    line-height: .5;
    margin-bottom: 50px !important;
    max-width: 90%;
  } 
  .homeService-img h2 .color-yellow {
    color: var(--primaryColor);
  }
  .homeService-img h2 .color-gray {
    color: #b6b6b6;
  }
  .homeService-img-text {
    max-width: 95%;
    padding: 18px 10px;
    border-bottom: 1px solid rgb(254 254 254 / 17%);
  } 
  .homeService-img-text:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
  .homeService-title p {
    max-width: 80%;
    font-size: 17px;
    color: #b6b6b6 !important;
  }
  .homeService-title h6 {
    color: #b6b6b6 !important;
    font-size: 18px;
    padding: 10px 0;
  }
  .homeService-title h4 {
    color: #ffffff;
  }
  .homeService-box img {
    /* border-radius: 20px; */
  }
  .homeProjects {
    padding: 80px 0;
  }
  .homeProjects-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  .homeProjects-box { 
    padding: 0 15px;
  }
  .homeProjects-box .img1 { 
    width: 100%;
    height: 360px;
    object-fit: cover;
    object-position: center;
  }
  .homeProjects-text { 
    display: flex;
  }
  .homeProjects-text h4 { 
    font-size: 26px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 6px;
  }
  .homeProjects-text p { 
    padding-bottom: 6px;
    max-width: 90%;
  }  
  .homeProjects-text a { 
    transition: all ease-out .5s;
  }
  .homeProjects-text a:hover { 
    opacity: .8;
  }
  .homeProjects-text .img2 {
    position: relative;
    top: 30%;
    width: 100%;
  }  
.homeProjects .slick-prev, .homeProjects .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 28%;
}
.slick-prev:before, .slick-next:before {
    content: '' !important;
}
.slick-arrow {
    z-index: 10000;
}
.homeReal-box {
    background: var(--primaryColor);
    margin-bottom: 88px;
    padding: 45px;
    position: relative;
}
.homeReal-box h2 {
    font-size: 44px !important;
    padding-left: 2%;
    line-height: 1.5;
    font-weight: 600;
    color: var(--pColor);
}
.homeReal-box .span1 {
    display: block;
}
.homeReal-box .span2 {
    display: block;
}
.homeReal-box .span3 {
    display: block;
}
.homeReal-box .span4 {
    color: var(--secondaryColor);
}
.homeReal-box .span4 span {
    color: var(--hColor);
}
.homeReal-box:before {
    position: absolute;
    content: "";
    right: 0;
    top: 10%;
    width: 40%;
    height: 75%;
    opacity: 1;
    background: url(../img/bg-logo-2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 1;
}
.homeTime-box {
    display: flex;
    align-items: center;
}
.homeTime-box h4 {
    font-size: 64px;
    font-weight: 400;
    margin-right: 25px;    
}
.homeTime-text h5 {
    font-size: 26px;
    color: var(--secondaryColor);
}
.homeTime-text p {
    max-width: 85%;
}
.homeConnect-box {
    position: relative;
    margin-top: 25px;
    margin-bottom: 88px;
    padding: 70px 75px;
    background: var(--primaryColor);
}
.homeConnect-box:before {
    position: absolute;
    content: "";
    left: -12%;
    top: 5%;
    width: 50%;
    height: 65%;
    opacity: 1;
    background: url(../img/bg-logo-2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 0;
}
.homeConnect-box h2 {
    position: relative;
    line-height: 1.5;
    font-size: 44px !important;
    z-index: 1;
}
.homeConnect-link {
    height: 100%;
    display: flex;
    justify-content: center;
}
.homeConnect-box img {
    width: 30%;
    animation: wiggle 2s linear infinite;
}
.homeConnect-box img:hover {
    opacity: .8;
}
.homeCategory {
    padding: 86px 0 60px 0;
}
.homeCategory h4 {
    border-bottom: 1px solid #bec2c9;
    color: var(--secondaryColor);
    padding-bottom: 6px;
    padding-top: 8px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}
.homeCategory h4 span {
    font-size: 12px;
}
.homeCategory-box1 {
    padding: 11px;
    background: #fff;
    margin-bottom: 9px;
    display: block;
}
.homeCategory-box1 p {
    line-height: 1.5;
    margin-bottom: 9px;
    color: var(--pColor);
}
.homeCategory-box1 img {
    height: 170px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform .5s ease;
}
.homeCategory-box1:hover img {
    opacity: 0.8;
}
.homeCategory-box2 {
    margin-bottom: 10px;
}
.homeCategory-box2 .carousel-inner > .item {
    position: relative;
    display: none;
    -webkit-transition: 0.1s ease-in-out left;
    -moz-transition: 0.1s ease-in-out left;
    -o-transition: 0.1s ease-in-out left;
    transition: 0.1s ease-in-out left;
}
.homeCategory-box2 .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    background-color: #000000;
}
.homeCategory-box2 .carousel-control-prev, .homeCategory-box2 .carousel-control-next {
    display: none;
}
.homeCategory-box2 img {
    width: 100%;
    height: 291px;
    object-fit: cover;
    object-position: center;
}
.homeCategory-box2 h1 {
    font-size: 44px;
    font-weight: 700;
    padding-bottom: 22px;
    border-bottom: 1px solid #ffdc92;
}
.homeCategory-box2 h1 .span1 {
    display: block;
}
.homeCategory-box2 h1 .span2 {
    display: block;
    padding-right: 15px;
}
.homeCategory-box2 p {
    margin-bottom: 0;
    padding-top: 6px;
    font-weight: 500;
}
.homeCategory-box2:hover img {
    opacity: 0.8;
}
.homeCategory-box3 {
    padding: 11px;
    background: #fff;
    margin-bottom: 25px;
    display: block;
}
.homeCategory-box3 img {
    width: 100%;
    height: 215px;
}
.homeCategory-box3:hover img {
    opacity: 0.8;
}
.clientlogo {
    padding-bottom: 40px;
}
.clientlogo h2 {
    border-bottom: 1px solid #555455;
}
.clientlogo-box {
    padding: 20px 0;
    
}
.clientlogo-box img {
    max-width: 90%;
    padding: 20px;
    background: #fff
}
.clientlogo .slick-prev, .clientlogo .slick-next {
    top: 40% !important;
}
/* Home Banner End */
/* Latest Work Start */
.latestWork {
    padding: 60px 0 50px 0;
}
.latestWork h2 {
    border-bottom: 1px solid #555455;
}
.latestWork-box {
    position: relative;
    margin-bottom: 10px;
}  
.latestWork-box img {
    height: 185px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.latestWork-box .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(255 197 7 / 75%);
}   
.latestWork-box:hover .overlay {
    opacity: 1;
}  
.latestWork-box .text {
    position: absolute;
    display: flex;
    width: 100%;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
}
.latestWork-box .text h4 {
    color: var(--hColor);
    font-size: 20px;
    font-weight: 600;
}
.latestWork-box .text p {
    color: #171717;
    text-transform: uppercase;
}
.latestWork-box .overlay::after {
    content: '';
    position: absolute;
    border: 1px solid var(--hColor);
    width: 84%;
    height: 78%;
    margin: 10% 8.5%;
    transform: rotate(10deg);
    transition: all .3s ease-in-out;
} 
.latestWork-box:hover .overlay::after {
    transform: rotate(0deg);
} 
.latestWork .latestWork-box .overlay::after {
    height: 68%;
}
/* Latest Work End */
/* Footer Services Start */
.footerServices {
    padding: 60px 0 40px 0;
    background: #fff;
}
.footerServices h2 {
    border-bottom: 1px solid #555455;
}
.footerServices li {
    position: relative;
    list-style: none;
    color: var(--pColor);
    padding-bottom: 5px;
    margin-left: -6px;
}
.footerServices li a {
    color: var(--pColor);
    transition: all 0.3s ease;
}
.footerServices li:hover a {
    color: var(--primaryColor) !important;
}
.footerServices li a:hover::before {
    content: '+';
    position: absolute;
    left: -18px;
    top: -2px;
    font-size: 18px;
    color: var(--primaryColor);    
}
.footerServices h4 {
    position: relative;  
    text-transform: uppercase;
    margin-left: 25px;
}
.footerServices h4 a {
    color: var(--pColor);
    font-size: 20px;
    font-weight: 600;
}
.footerServices h4 a::after {
    content: '+';
    position: absolute;
    left: -32px;
    top: 4px;
    font-size: 20px;
    color: var(--primaryColor);
    border: 1.5px solid var(--primaryColor);
    width: 24px;
    height: 24px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;   
    transform: scale(1);
    transition: all 0.3s ease-in-out 0.2s;
}
.footerServices h4 a:hover::after {
    content: '+';
    position: absolute;
    left: -32px;
    top: 4px;
    font-size: 20px;
    color: var(--primaryColor);
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;   
    transform: translateX(10px);
    transition: all 0.3s ease-in-out 0.1s;
}
.footerSocial p {
    color: #7e8989;
    margin-bottom: 0;
}
.footerSocial p a {
    color: #7e8989;
}
.footerSocial p a:hover {
    color: var(--primaryColor);
}
.footerSocial a img {
    margin-bottom: 10px;
}
/* Footer Services End */
/* Left Whatsapp Start */
.leftWhatsapp {
    align-content: flex-end;
    align-items: center;
    bottom: 6%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    position: fixed;
    right: 2%;
    z-index: 10;
}
.leftWhatsapp img {
    width: 80px;
}
/* Left Whatsapp End */
/* Footer Start */
footer {
    background: #3b3f3f;
    /* background-image: url('../img/footer-bg.png'); */
    background-position: bottom;
    background-size: 100% 50%;
    background-repeat: no-repeat;
    padding: 50px 0 40px 0;
    position: relative;
    z-index: 1;
}
.footerLink h4 {
    color: #fff;
    font-size: 18px;
    padding-bottom: 10px;
    position: relative;
}
.footerLink h4::before {
    content: '';
    left: 0;
    top: 13px;
    background: #636363;
    height: .8px;
    width: 94%;
    position: absolute;
}
.footerLink h4 span {
    background: #3b3f3f;
    position: relative;
    padding-right: 10px;
    font-weight: 300;
}
.footerLink a, .footerLink p {
    display: block;
    color: #7e8989;
    margin-bottom: 6px;
}
.footerLink a:hover {
    color: var(--primaryColor);
}
.footerAddr p {
    display: flex;
    margin-bottom: -4px;
    color: #7e8989;
    font-size: 15px;
}
.footerAddr h4 {
    color: #fff;
    font-size: 18px;
    padding-bottom: 10px;
    position: relative;
}
.footerAddr h4::before {
    content: '';
    left: 0;
    top: 13px;
    background: #636363;
    height: .8px;
    width: 94%;
    position: absolute;
}
.footerAddr h4 span {
    background: #7e8989;
    position: relative;
    padding-right: 10px;
    font-weight: 300;
}
.footerAddr a {
    color: #7e8989;
    padding-left: 8px;
}
.footerAddr a:hover {
    color: var(--primaryColor);
}
.footerAddr h5 {
    color: #fff;
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 10px;
}
.footerSocial h4 {
    color: #fff;
    font-size: 18px;
    padding-bottom: 10px;
    position: relative;
}
.footerSocial h4::before {
    content: '';
    left: 0;
    top: 13px;
    background: #636363;
    height: .8px;
    width: 94%;
    position: absolute;
}
.footerSocial h4 span {
    background: #3b3f3f;
    position: relative;
    padding-right: 10px;
    font-weight: 300;
}
.footerSocial a img {
    width: 28px;
    height: 28px;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
}
.footerSocial a:hover img {
    opacity: .8;
    transform: scale(1.2);
}
.privacyLink a {
    color: #bababa;
    margin-top: 14px;
    display: block;
    margin-bottom: -10px;
}
.privacyLink a:hover {
    color: var(--primaryColor);
}
.footerCopyright {
    color: #bababa;
    font-size: 12px;
    margin-top: 10px;
}
/* Footer End */
/* Brochur Start */
.brocureBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.brocureBox-h4 {
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    color: var(--secondaryColor) !important;
}
.brocureBox-file {
    width: 17%;
    background: #ebebeb;
    padding: 10px;
    margin: 10px;
    display: block;
    transition: all 0.3sease-in-out;
}
.brocureBox-file:hover {
    /* transform: scale(1.1); */
    background: var(--primaryColor);
}
.brocureBox-img {
    position: relative;
}
.brocureBox-img-download {
    position: absolute;
    width: 24px;
    bottom: 5px;
    right: 5px;
    background: #fff;
    padding: 4px;
    box-shadow: 0px 5px 50px rgb(3 24 55 / 10%);
}
.brocureBox-file h4 {
    font-weight: 600;
    color: var(--hColor);
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
}
/* Brochur End */
/* Inner Pages Start */
.innerPages {
    padding-top: 85px;   
}
.innerPages-box1 {
    padding: 25px;
    margin-bottom: 10px;
    height: 350px;
    display: flex;
    background: var(--primaryColor);
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.innerPages-box1 h1 {
    font-size: 32px;
    font-weight: 700;
    width: 100%;
    padding-bottom: 5px;
    text-transform: uppercase;
    border-bottom: 2px solid #e0b00f;
}
.innerPages-box1 h1 span {
    display: block;
}
.innerPages-box1 h4 {
    color: #b48d0f;
    font-size: 20px;
    line-height: 1.25;
    text-transform: uppercase;
}
.innerPages-box2 {
    padding: 25px;
    height: 350px;
    background: #fff;
}
.innerPages-box3 {
    padding: 25px;
    height: 350px;
    background: #fff;
}
.innerPages-box5 {
    padding: 25px;
    height: 350px;
    margin: 10px 0;
    background: #fff;
}
/* Inner Pages End */
/* About Page Start */
.aboutPages-box2 img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}
.aboutPages-logo h4 {
    border-bottom: 1px solid #bec2c9;
    color: var(--secondaryColor);
    padding-bottom: 6px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}
.aboutPages-box5 {
    padding: 25px;
    margin-bottom: 10px;
    background: #fff;
}
.aboutPages-box5 h4 {
    color: var(--secondaryColor);
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}
/* About Page End */
/* Services Start */
.recentproLinks {
    padding: 20px 0;
    margin-left: 15px;
}
.recentproLinks hr {
    width: 90%;
}
.recentproLinks li {
    list-style: none;
    font-size: 18px;
    line-height: 1.4;
    margin: 10px 0;
}
.recentproLinks li a {
    color: var(--pColor);
    position: relative;
}
.recentproLinks li a:after {
    content: "\203A";
    color: var(--secondaryColor);
    font-size: 22px;
    margin-left: 4px;
}
.recentproLinks li a:hover {
    color: var(--secondaryColor);
}
.snapshotsImg {
    padding-top: 10px;
    position: relative;
    cursor: pointer;
    transition: transform .2s;
}
.snapshotsImg-img {
    width: 100%;
    height: 150px !important;
    object-fit: cover;
    object-position: center;    
}
.snapshotsImg-icon {
    position: absolute;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    background: rgb(0 0 0 / 15%);
    transition: transform .2s;
}
.snapshotsImg:hover .snapshotsImg-icon {
    background: rgb(0 0 0 / 50%);
}
.snapshotsImg-icon img {
    width: 45%;
    transition: transform .2s;
}
.snapshotsImg:hover .snapshotsImg-icon img {
    transform: scale(1.25);
}
.snapshotsModal .modal-content {
    background: transparent;
    border: none;
}
.modal {
    background: rgba(7, 4, 31, 0.35);
}
.snapshotsModal .modal-header {
    padding: 0;
    margin: 0;
    border: 0;
}
.snapshotsModal .modal-body {
    padding: 15px 0;
}
.btn-close {
    background-color: var(--secondaryColor) !important; 
    opacity: 1 !important; 
} 
.btn-close:hover {
    background-color: var(--primaryColor) !important; 
} 
.btn-close:focus {
    box-shadow: none !important;
}  
.btn-close::before {
    content: '✖';
    font-size: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.servicesInner-content {
    padding-bottom: 25px;
    margin-top: -5px;
    padding-left: 12px;
    padding-right: 12px;
}
.servicesInner-content h4 {
    border-bottom: 1px solid #bec2c9;
    color: var(--secondaryColor);
    padding-bottom: 6px;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
}
.servicesInner-content h4 span {
    color: var(--hColor);
    font-weight: 800;
}
.servicesInner-content p {
    margin-bottom: 8px;
}
.servicesInner-content .latestWork-box h4 {
    border: none !important;
}
.servicesInner-box1 {
    position: relative;
    margin-bottom: 10px;
}  
.servicesInner-box1 img {
    height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.servicesInner-box1 .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(255 197 7 / 75%);
}   
.servicesInner-box1:hover .overlay {
    opacity: 1;
}  
.servicesInner-box1 .text {
    position: absolute;
    display: flex;
    width: 100%;
    height: 208px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
}
.servicesInner-box1 .text h4 {
    color: var(--hColor);
    font-size: 28px;
    font-weight: 700;
    border: none;
    position: relative;
    top: 40%;
    width: 40%;
    line-height: 1.35;
}
.servicesInner-box1 .text p {
    color: #171717;
    text-transform: uppercase;
}
.servicesInner-box1 .overlay::after {
    content: '';
    position: absolute;
    border: 1px solid var(--hColor);
    width: 84%;
    height: 80%;
    margin: 8% 8.5%;
    transform: rotate(10deg);
    transition: all .3s ease-in-out;
} 
.servicesInner-box1:hover .overlay::after {
    transform: rotate(0deg);
} 

.servicesInner-box2 {
    position: relative;
    margin-bottom: 10px;
}  
.servicesInner-box2 img {
    height: 170px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.servicesInner-box2 .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(255 197 7 / 75%);
}   
.servicesInner-box2:hover .overlay {
    opacity: 1;
}  
.servicesInner-box2 .text {
    position: absolute;
    display: flex;
    width: 100%;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
}
.servicesInner-box2 .text h4 {
    color: var(--hColor);
    font-size: 28px;
    font-weight: 700;
    border: none;
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
    line-height: 1.35;
}
.servicesInner-box2 .text p {
    color: #171717;
    text-transform: uppercase;
}
.servicesInner-box2 .overlay::after {
    content: '';
    position: absolute;
    border: 1px solid var(--hColor);
    width: 84%;
    height: 62%;
    margin: 7.5% 8.5%;
    transform: rotate(10deg);
    transition: all .3s ease-in-out;
} 
.servicesInner-box2:hover .overlay::after {
    transform: rotate(0deg);
} 
.serviceWhite {
    padding-bottom: 0;
}
/* Services End */
/* Project Start */
.projectPages-box5 {
    padding: 25px;
    margin-bottom: 10px;
    background: #fff;
}
.projectPages-box5 .nav-tabs .nav-item.show .nav-link, .projectPages-box5 .nav-tabs .nav-link, .projectPages-box5 .nav-tabs .nav-link.active {
    border: none;
    border-color: var(--bs-nav-tabs-link-active-border-color);
    border-radius: 0;
    color: #000 !important;
    color: var(--hColor) !important;
    display: inline-block;
    font-weight: 500;
    height: 36px;
    opacity: 1;
    padding: 6px 30px;
    margin-bottom: 6px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    background: #ebebeb;
    transition: all .5sease-out;
}
.projectPages-box5 .nav-tabs .nav-item.show .nav-link, .projectPages-box5 .nav-tabs .nav-link.active {
    background: var(--primaryColor) !important;
}
/* Project End */
/* Client Start */
.clientPages-box5 {
    padding: 25px;
    margin-bottom: 10px;
    background: #fff;
}
.clientPages-box5 img {
    padding: 22px;
    filter: grayscale(100%);
    transition: allease-out .5s !important;
}
.clientPages-box5 img:hover {
    filter: grayscale(0);
}
/* Client End */
/* Job Start */
.jobBox h4 {
    border-bottom: 1px solid #bec2c9;
    color: var(--secondaryColor);
    padding-bottom: 6px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
}
.jobBox .accordion-header {
    margin-bottom: 5px !important;
}
.jobListing {
    display: block;
}
.jobBox .accordion-header button {
    padding: 15px 0;
}
.jobBox .accordion-header h5 {
    font-weight: 600;
    font-size: 18px;
}
.jobBox .accordion-header p {
    font-size: 14px;
    color: var(--pColor);
}
.jobBox .lable {
    margin-top: 15px;
}
.jobBox .lable span {
    font-size: 11px;
    font-weight: 500;
    opacity: 0.9;
    color: var(--hColor);
    padding: 3px 6px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #E5E9F1;
}
.jobBox .accordion-item {
    display: block;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(190, 194, 201, 0.475);
}
.jobBox .accordion-button:not(.collapsed), .jobBox .accordion-button:focus {
    color: inherit;
    background-color: transparent;
    box-shadow: none;
}
.jobBox .accordion-item:last-of-type {
    border-radius: 0;
}
.applyBtn {
    background: #0e864d;
    color: #fff;
    opacity: 1;
    padding: 3px 26px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
    width: max-content !important;
    display: -webkit-inline-flex;
    border-radius: 0;
    border: none;
    transition: all ease-out .5s;
    position: relative;
}
.applyBtn:hover {
    background: #0e864d;
    color: #fff;
    opacity: 0.65;    
}
/* Job End */
/* Contact Us Start */
.contactForm-address {
    padding-bottom: 25px;
    margin-top: -5px;
    padding-left: 12px;
    padding-right: 12px;
}
.contactForm-address h4 {
    border-bottom: 1px solid #bec2c9;
    color: var(--secondaryColor);
    padding-bottom: 6px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
}
.contactForm-address h5 {
    color: var(--hColor);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
}
.contactForm-address p {
    margin-bottom: 5px;
    font-weight: 400;
    color: var(--pColor);
}
.contactForm-address a {
    color: var(--hColor);
    font-weight: 500;
    transition: all 0.3sease-in-out;
}
.contactForm-address a:hover {
    color: var(--primaryColor);
}
.contactForm-form {
    padding-bottom: 25px;
    margin-top: -5px;
    padding-left: 12px;
    padding-right: 12px;    
}
.contactForm-form h4 {
    border-bottom: 1px solid #bec2c9;
    color: var(--secondaryColor);
    padding-bottom: 6px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}
.contactForm-form input, .contactForm-form textarea {
    border-radius: 0;
    font-size: 14px !important;
}
.submitBtn, .submitBtn:focus {
    background: var(--primaryColor) !important;
    color: var(--hColor) !important;
    text-align: center !important;
    height: 38px;
    text-transform: uppercase;
    font-size: 14px !important;
    padding: 2px 20px 2px 20px !important;
    display: inline-block !important;
    font-weight: 500 !important;
    opacity: 1;
    transition: allease-out .5s !important;
    position: relative;
    border: none !important;
}
.submitBtn:hover{
	opacity: 0.8;	
}
.contactMap-text h3 {
    border-bottom: 1px solid #bec2c9;
    padding-bottom: 6px;
    margin-bottom: 15px;
    margin-top: 15%;
    font-size: 24px;
}
.contactMap iframe {
    width: 100%;
    height: 303px;
}
/* Contact Us */
@media screen and (max-width: 480px) {    
    .navbar .dropdown-menu {
        display: none; 
    }
    .navbar-nav .dropdown-menu {
        position: initial !important;
    }
    .navbar.active .container .collapse.show {
        padding-bottom: 18px !important;
    }
    .navbar {
        background: #fff;
    }
    .navbar.active .collapse {
        background: #fff;
    }
    h1 {
        font-size: 36px !important;
        padding-bottom: 10px;
    }
    h2 {
        font-size: 44px !important;
    }
    .homeBanner {
        padding: 145px 0 50px 0;
    }
    .homeBanner h1 .span1 {
        font-size: 36px !important;
    }
    .homeBanner h1 .span2 img {
        width: 60px;
    }
    .homeBanner p {
        font-size: 16px;
    }  
    .homeService {
        padding: 10px 0 0px 0;
    }  
    .homeCounter-counter h4 {
        margin-bottom: 0;
        margin-top: 20px;
    }
    .homeCounter-title {
        font-size: 16px;
        margin-top: 5px;
    }
    .media-count-value {
        font-size: 46px;
    }
    .homeCounter-plus {
        font-size: 46px;
        margin-left: 0;
    }
    .homeService-img h2 {
        font-size: 32px !important;
        margin-bottom: 40px;
        max-width: 100%;
    }
    .homeService-title p {
        max-width: 100%;
    }
    .leftSocial {
        display: none;
    }
    .homeCounter-box h3 {
        font-size: 36px;
    }
    .homeCounter-counter {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .homeCounter-box2 h4 {
        font-size: 32px;
    }
    .homeProjects .slick-prev img {
        display: block;
        left: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        position: relative;
    }
    .homeProjects .slick-next img {
        display: block;
        right: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        position: relative;
    }
    .homeReal-box h2 {
        padding-left: 0;
        line-height: 1.75;
        font-size: 24px !important;
    }
    .homeTime-text p {
        max-width: 100%;
    }
    .homeTeam .slick-prev img {
        display: block;
        left: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        position: relative;
    }
    .homeTeam .slick-next img {
        display: block;
        right: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        position: relative;
    }
    .homeConnect-box:before {
        left: 0;
    }
    .homeConnect-box {
        padding: 40px;
    }
    .homeConnect-box img {
        width: 20%;
        margin-top: 10px;
    }
    .homeConnect-box h2 {
        font-size: 26px !important;
    }
    .footerLink {
        margin-bottom: 20px;
    }
    .homeProjects-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footerLogo {
        text-align: left;
        padding: 20px 0;
    }
    .footerLogo img {
        width: 22%;
    }
    .footerSocial {
        margin-top: 20px;
    }
    .footerLink h4 {
        padding-bottom: 0;
    }
    .homeCategory-box2 h1 {
        font-size: 40px !important;
    }
    .homeCategory-box3 img {
        height: 150px;
    }
    .homeService .homeService-img {
        padding: 18% 5%;
    }
    .contactForm h2 {
        font-size: 32px !important;
    }
    .projectTab .nav-tabs .nav-link {
        margin-bottom: 10px !important;
    }
    .footerMail a {
        font-size: 24px;
    }
    .footerAddress h4 {
        font-size: 22px;
    }
    .brocureBox-file {
        width: 26%;
        padding: 6px;
        margin: 6px;
    }
    .brocureBox-file h4 {
        font-size: 12px;
    }
    .brocureBox-img-download {
        width: 20px;
        padding: 2px;
    }
    .brandServices-one img {
        margin-bottom: 30px;
    }
    .brandServices-one {
        padding: 60px 0 0 0;
    }
    .brandServices-two {
        padding: 55px 0;
    }
    .brandServices-two img {
        margin-bottom: 20px;
        width: 100%;
    }
    .eventsServices-one {
        padding-top: 35px;
    }
    .eventsServices-two h3 {
        padding-top: 0px;
    }
    .clientlogo .slick-next {
        right: 0;
    }
    .clientlogo .slick-prev {
        left: 0;
    }
    .innerPages-box2 {
        height: auto;
        margin: 10px 0;
    }
    .contactMap-text h3 {
        margin-top: 5%;
    }
    .servicesInner-box1 .overlay::after {
        height: 86%;
    }
    .servicesInner-box1 .text h4 {
        font-size: 22px;
        width: 65%;
    }
    .servicesInner-box2 .text h4 {
        font-size: 22px;
    }
    .servicesInner-box2 .overlay::after {
        height: 74%;
    }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 696px) {   
    .brandServices-one {
        padding: 50px 0 0 0;
    }
    .brandServices-one img {
        margin-bottom: 30px !important;
    }
    .brandServices-two img {
        width: 100%;
        margin-bottom: 30px;
    }
    .eventsServices-one {
        padding-top: 35px;
    }
    .eventsServices-two h3 {
        padding-top: 0px;
    }
    .eventsServices-two-box {
        padding: 45px 0 80px 0;
    }
    .innerPages-box2 {
        height: auto;
        margin: 10px 0;
    }
    .contactMap-text h3 {
        margin-top: 5%;
    }
    .servicesInner-box1 .overlay::after {
        height: 86%;
    }    
}
@media only screen and (min-device-width: 698px) and (max-device-width: 798px) {
    .navbar .dropdown-menu {
        display: none; 
    }
    .navbar-nav .dropdown-menu {
        position: initial !important;
    }
    .navbar.active .container .collapse.show {
        padding-bottom: 18px !important;
    }
    .navbar {
        background: #fff;
    }
    .navbar.active .collapse {
        background: transparent;
    }
    .navmenuCenter {
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-top: 10px;
        margin-top: 8px;
        margin-bottom: 20px !important;
    }
    .homeCategory h1 {
        font-size: 28px !important;
        line-height: 1.25;
    }
    h2 {
        font-size: 44px !important;
    }
    .homeBanner h1 .span1 {
        font-size: 62px !important;
    }
    .homeBanner h1 .span2 img {
        width: 60px;
    }
    .homeBanner p {
        font-size: 16px;
    }
    .leftSocial {
        right: -8%;
    }
    .homeService {
        padding: 22px 0 0 0;
    }
    .homeService-title h4 {
        padding-bottom: 40px;
    }
    .homeService-img-text p {
        max-width: 100%;
    }
    .homeService-img-text {
        max-width: 100%;
    }
    .homeService-img-text h4 {
        font-size: 24px;
    }
    .homeProjects .slick-prev img {
        display: block;
        left: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        position: relative;
    }
    .homeProjects .slick-next img {
        display: block;
        right: 30px;
        background: var(--primaryColor);
        height: 45px;
        width: 45px;
        padding: 3px;
        position: relative;
    }
    .homeConnect-box h2 {
        font-size: 40px !important;
    }
    .homeConnect-box:before {
        position: absolute;
        content: "";
        left: 4%;
        top: 0;
    }
    .homeConnect-box img {
        width: 16%;
    }   
    .homeCategory-box3 img {
        height: 176px;
        object-fit: cover;
        object-position: center;
    } 
    .homeService .homeService-img {
        padding: 10% 5%;
    }
    .contactForm h2 {
        font-size: 40px !important;
    }  
    .homeCounter-counter {
        display: flex;
        justify-content: space-between;
    }
    .homeCounter-plus {
        color: #b6b6b6;
        font-size: 55px;
        margin-left: -5px;
    }
    .media-count-value {
        font-size: 46px;
    }  
    .navbar-nav .nav-link, .navbar-nav .nav-link:focus {
        padding: 10px 0;
        margin: 0;
    }
    .brocureBox-file {
        width: 28%;
        padding: 8px;
        margin: 8px;
    }
    .brocureBox-file h4 {
        font-size: 14px;
    }
    .homeCategory-box3 {
        margin-bottom: 0;
    }
    .homeCategory-box3 img {
        height: 215px;
    }
    .innerPages-box2 {
        height: auto;
        margin: 10px 0;
    }
    .contactMap-text h3 {
        margin-top: 5%;
    }
    .servicesInner-box1 .overlay::after {
        height: 87%;
    }
    .servicesInner-box1 .text h4 {
        font-size: 24px;
        width: 62%;
    }
    .servicesInner-box2 .overlay::after {
        height: 75%;
    }
    .servicesInner-box2 .text h4 {
        font-size: 24px;
    }
}
@media only screen and (min-device-width: 981px) and (max-device-width: 1024px) {
    .navbar-nav .nav-link, .navbar-nav .nav-link:focus {
        font-size: 14px;
        padding: 12px 8px !important;
        margin: 0 5px;
    }
    .navbar-brand img {
        max-width: 75%;
        transition: all .4s ease-in-out;
    }
    .homeCategory-box1 p {
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        line-height: 1;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-inline-box;
    }
    .homeCategory h4 {
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .homeCategory-box1 img {
        height: 175px;
    }
}
@media screen and (min-width: 1000px) {   
    .navbar-expand-lg .navbar-collapse {
        margin-top: 18px !important;
    }
    .row>* {
        padding-right: calc(var(--bs-gutter-x)* .2) !important;
        padding-left: calc(var(--bs-gutter-x)* .2) !important;
    }
}